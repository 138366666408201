import React from 'react'
import styled from 'styled-components'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 2.4rem;
  margin-bottom: 4rem;
  @media (min-width: 1024px) {
    padding: 4rem 0;
  }
`

const Title = styled.h1`
  font-family: ${fontfamily.jaRounded};
  color: ${colors.lightBlack};
  font-size: 2.2rem;
  font-weight: 700;
  @media (min-width: 1024px) {
    font-size: 2.8rem;
  }
`

const SubTitle = styled.span`
  color: ${colors.green};
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  @media (min-width: 1024px) {
    font-size: 1.6rem;
  }

`

const Heading = props => {
  const {title, titleEn} = props
  return (
    <Wrapper>
      {titleEn && <SubTitle>{titleEn}</SubTitle> }
      {title && <Title>{title}</Title> }
    </Wrapper>
  )
}
export default Heading
