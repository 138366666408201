import React from 'react'
import styled from 'styled-components'

import {Link} from 'gatsby'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  @media (min-width: 1024px) {
    margin: 6rem 0;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: ${fontfamily.jaRounded};
  font-size: 1.6rem;
  display: block;
  border-radius: 100px;
  border: 2px ${colors.blue} solid;
  color: ${colors.blue};
  text-decoration: none;
  text-align: center;
  width: auto;
  padding: 0.6rem 2.4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  `

const Button = props => {
  const {label, link} = props
  return (
    <Wrapper>
      <StyledLink to={link}>
        {label}
      </StyledLink>
    </Wrapper>
  )
}
export default Button
